import './App.css';
import React, {useState, useEffect} from 'react';
import Header from './header/Header';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import { styled } from '@mui/material/styles';
import MegaMillBody from './megamillion/MegaMillBody'
import { Routes, Route } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Home from './Home'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// const Skeleton = styled('div')(({ theme, height }) => ({
//   backgroundColor: theme.palette.action.hover,
//   borderRadius: theme.shape.borderRadius,
//   height,
//   content: '" "',
// }));



const App = () => {
  const navigate = useNavigate();
  const headerNavigation = (data) => {
    if (data) {
      navigate('/'+data);
    }
  }
  const [isMobile, setIsMobile] = useState(false)
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  useEffect(() => {
    handleResize();
  }, [])

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header headerNavigation={headerNavigation}/>
          <Routes>
            <Route path="/" element={<Home isMobile={isMobile} />} />
            <Route path="/megamillion" element={<MegaMillBody isMobile={isMobile} />} />
            <Route path="/privacy" element={<PrivacyPolicy isMobile={isMobile} />} />
            <Route path="/terms" element={<TermsOfService isMobile={isMobile} />} />
          </Routes>
        {/* <Grid container spacing={1} sx={{margin:'10px'}}>
            <Grid size={5} />
            <Grid size={12}>
              <Skeleton height={14} />
            </Grid>
            <Grid size={12}>
              <Skeleton height={14} />
            </Grid>
            <Grid size={4}>
              <Skeleton height={100} />
            </Grid>
            <Grid size={8}>
              <Skeleton height={100} />
            </Grid>

            <Grid size={12}>
              <Skeleton height={150} />
            </Grid>
            <Grid size={12}>
              <Skeleton height={14} />
            </Grid>

            <Grid size={2}>
              <Skeleton height={100} />
            </Grid>
            <Grid size={8}>
              <Skeleton height={100} />
            </Grid>
            <Grid size={2}>
              <Skeleton height={100} />
            </Grid>
          </Grid> */}
          
          </ThemeProvider>
    </>
  );
}

export default App;

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid2';
import CircularProgress from '@mui/material/CircularProgress';

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const numberChageDate = new Date('2017-11-01T00:00:00.000');

const MegaMillBody = ({isMobile}) => {
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState(null);
  const [dataNumbers, setDataNumbers] = useState(null);
  const [megaBallNumbers, setMegaBallNumbers] = useState(null);
  const searchAmount = 1000;
  const [isLoading, setIsLoading] = useState(false)

  const columns = [
    {
      field: 'draw_date',
      headerName: 'Draw Date',
      width: 200,
      editable: false,
      valueGetter: (value, row) => {
        var mydate = new Date(value);
        return mydate.toDateString();
      },
    },
    {
      field: 'winning_numbers',
      headerName: 'Winning Numbers',
      width: 150,
      editable: false,
    },
    {
      field: 'mega_ball',
      headerName: 'Megaball',
      width: 110,
      editable: false,
    },
    {
      field: 'multiplier',
      headerName: 'Multiplier',
      width: 100,
      editable: false,
    },
  ];
  
  const columnsLastDrawn = [
    {
      field: 'id',
      headerName: 'Number',
      width: 150,
      editable: false
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 150,
      editable: false
    },
    {
      field: 'date',
      headerName: 'Last Time Drawn',
      width: 200,
      editable: false,
      type: 'Date',
      valueGetter: (value, row) => {
        var mydate = new Date(value);
        return mydate.toDateString();
      },
    },
  ];
  
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      fetch('https://data.ny.gov/resource/5xaw-6ayf.json?%24limit='+searchAmount.toString())
        .then((response) => response.json())
        .then((result) => {
          setData(result);
          setIsLoading(false);    
        })
      }
    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      const temp = [];
      const tempMega = [];
      data.forEach((xValue, xIndex) => {
        if (numberChageDate < new Date(xValue["draw_date"])) {
          const split = xValue["winning_numbers"].split(" ");
          split.forEach((yValue,yIndex) => {
            const found = temp.find(o => o?.['id'] === yValue);
            if (found) {
              temp[found.index]["total"] += 1;
            } else {
              temp.push({
                "id": yValue,
                "date": xValue["draw_date"],
                "total": 1,
                "index": (temp?.length? temp?.length:0)
              })
            }
          })

          const foundMegaBall = tempMega.find(o => o?.['id'].padStart(2, '0') === xValue["mega_ball"].padStart(2, '0'));
          if (foundMegaBall) {
            tempMega[foundMegaBall.index]["total"] += 1;
          } else {
            tempMega.push({
              "id": xValue["mega_ball"].padStart(2, '0'),
              "date": xValue["draw_date"],
              "total": 1,
              "index": (tempMega?.length? tempMega?.length:0)
            })
          }

        }
      });
      setDataNumbers(temp)
      setMegaBallNumbers(tempMega);
    }
  }, [data])
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading ? <CircularProgress color="secondary" /> : 
      <>
        <Grid container spacing={1} sx={{margin:'10px'}}>
          <Grid size={(isMobile?0:2)}>
          </Grid>
          <Grid size={(isMobile?12:8)}>
            <div style={{width:"100%", textAlign:'center'}}>
              <h2 style={{margin:'0'}}>Mega Millions - Last 1000 Draws</h2>
            </div>
          </Grid>
          <Grid size={(isMobile?0:2)}>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{margin:'10px'}}>
          <Grid size={(isMobile?0:2)}>
          </Grid>
          <Grid size={(isMobile?12:8)} sx={{border:1, borderRadius:2}}>
            <Box sx={{ width: '100%', padding:'2ex' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Draws" {...a11yProps(0)} />
                  <Tab label="Totals" {...a11yProps(1)} />
                  <Tab label="MegaBall Totals" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100, { value: -1, label: 'All' }]}
                    disableRowSelectionOnClick
                    getRowId={(row) => row?.['draw_date']}
                    sx={{maxHeight:'632px'}}
                  />
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <DataGrid
                    rows={dataNumbers}
                    columns={columnsLastDrawn}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                    disableRowSelectionOnClick
                    getRowId={(row) => row?.['id']}
                    sx={{maxHeight:'632px'}}
                  />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <DataGrid
                  rows={megaBallNumbers}
                  columns={columnsLastDrawn}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                  disableRowSelectionOnClick
                  getRowId={(row) => row?.['id']}
                  sx={{maxHeight:'632px'}}
                />
              </CustomTabPanel>
            </Box>
          </Grid>
          <Grid size={(isMobile?0:2)}>
          </Grid>
        </Grid>
      </>
      }
      
    </>
    
  );
}

export default MegaMillBody;
import React from 'react';
import Grid from '@mui/material/Grid2';

const Home = ({isMobile}) => {
  return (
    <>
      <Grid container spacing={1} sx={{margin:'10px'}}>
        <Grid size={(isMobile?0:2)}>
          </Grid>
          <Grid size={(isMobile?12:8)}>
            <div>
              <h1 style={{color: 'orange', textAlign:'center'}}>Welcome To PyLotto</h1>

              <p>PyLotto® is a place that I hope grows in ways to allow you to take different lottories, and utilize different mathematical
                equations to see the data in different ways to help you determine what possible numbers you might want to pick.</p>

              <p>
                For now there is only Mega Millions. I hope to add different equations as the site grows. I also have plans to add more Lottery Games in the future. 
              </p>

              <p></p><h2 style={{color: 'orange'}}>Contact Information</h2>
              I am only a single developer working on this site. If you have any ideas you would like to see added please email me at <a href="mailto:ideas@pylotto.com">ideas@pylotto.com</a><p></p>
            </div>
            
          </Grid>
          <Grid size={(isMobile?0:2)}>
          </Grid>
      </Grid>
    </>
  );
}

export default Home